export const KAVA = 2222

export const addresses = {
    [KAVA]: {
        KING: '0x0033d0c52228400240605caaee556320e00cd8bd',
        BTC: '0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b',
        ETH: '0xe3f5a90f9cb311505cd691a46596599aa1a0ad7d',
        KAVA: '0xc86c7c0efbd6a49b35e8714c5f59d99de09a225b',
        USDC: '0xfa9343c3897324496a05fc75abed6bac29f8a40f',
        USDT: '0xb44a9b6905af7c801311e8f4e76932ee959c663c',
        DAI: '0x765277eebeca2e31912c9946eae1021199b39c61',
        RewardReader: '0xe7301800eb969b3b9872575cba422506568e259d',
        KLP: '0x08aa7978501aca1235dbe32064a75d9ad4b9f1d3',
        KlpManager: '0x9a31dde84c61062fd799a13a00cc07e1b6fa8013'
    }
}

export function getAddress(chainId, key) {
    if (!(chainId) in addresses) {
        throw new Error(`Unknown chain ${chainId}`)
    }
    if (!(key in addresses[chainId])) {
        throw new Error(`Unknown address key ${key}`)
    }
    return addresses[chainId][key]
}
