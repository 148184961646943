import { useMemo, useState, useEffect } from 'react'
import { ApolloClient, InMemoryCache, gql, HttpLink } from '@apollo/client'
import { chain, sumBy, sortBy, maxBy, minBy } from 'lodash'
import fetch from 'cross-fetch';
import * as ethers from 'ethers'

import { fillPeriods } from './helpers'
import { addresses, getAddress, KAVA } from './addresses'

const BigNumber = ethers.BigNumber
const formatUnits = ethers.utils.formatUnits
const { JsonRpcProvider } = ethers.providers

import RewardReader from '../abis/RewardReader.json'
import KlpManager from '../abis/KlpManager.json'
import Token from '../abis/v1/Token.json'
import { el } from 'date-fns/locale';

const providers = {
  kava: new JsonRpcProvider('https://evm.kava.io'),
}

function getProvider(chainName) {
  if (!(chainName in providers)) {
    throw new Error(`Unknown chain ${chainName}`)
  }
  return providers[chainName]
}

function getChainId(chainName) {
  const chainId = {
    kava: KAVA,
    optimism: OPTIMISM
  }[chainName]
  if (!chainId) {
    throw new Error(`Unknown chain ${chainName}`)
  }
  return chainId
}

const NOW_TS = parseInt(Date.now() / 1000)
const FIRST_DATE_TS = parseInt(+(new Date(2021, 7, 31)) / 1000)

function fillNa(arr) {
  const prevValues = {}
  let keys
  if (arr.length > 0) {
    keys = Object.keys(arr[0])
    delete keys.timestamp
    delete keys.id
  }

  for (const el of arr) {
    for (const key of keys) {
      if (!el[key]) {
        if (prevValues[key]) {
          el[key] = prevValues[key]
        }
      } else {
        prevValues[key] = el[key]
      }
    }
  }
  return arr
}

export async function queryEarnData(chainName, account) {
  const provider = getProvider(chainName)
  const chainId = getChainId(chainName)
  const rewardReader = new ethers.Contract(getAddress(chainId, 'RewardReader'), RewardReader.abi, provider)
  const klpContract = new ethers.Contract(getAddress(chainId, 'KLP'), Token.abi, provider)
  const klpManager = new ethers.Contract(getAddress(chainId, 'KlpManager'), KlpManager.abi, provider)

  let depositTokens
  let rewardTrackersForDepositBalances
  let rewardTrackersForStakingInfo

  if (chainId === KAVA) {
    depositTokens = ['0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a', '0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA', '0x908C4D94D34924765f1eDc22A1DD098397c59dD4', '0x4d268a7d4C16ceB5a606c173Bd974984343fea13', '0x35247165119B69A40edD5304969560D0ef486921', '0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258']
    rewardTrackersForDepositBalances = ['0x908C4D94D34924765f1eDc22A1DD098397c59dD4', '0x908C4D94D34924765f1eDc22A1DD098397c59dD4', '0x4d268a7d4C16ceB5a606c173Bd974984343fea13', '0xd2D1162512F927a7e282Ef43a362659E4F2a728F', '0xd2D1162512F927a7e282Ef43a362659E4F2a728F', '0x4e971a87900b931fF39d1Aad67697F49835400b6']
    rewardTrackersForStakingInfo = ['0x908C4D94D34924765f1eDc22A1DD098397c59dD4', '0x4d268a7d4C16ceB5a606c173Bd974984343fea13', '0xd2D1162512F927a7e282Ef43a362659E4F2a728F', '0x1aDDD80E6039594eE970E5872D247bf0414C8903', '0x4e971a87900b931fF39d1Aad67697F49835400b6']
  } else {
    depositTokens = ['0x62edc0692BD897D2295872a9FFCac5425011c661', '0xFf1489227BbAAC61a9209A08929E4c2a526DdD17', '0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342', '0x908C4D94D34924765f1eDc22A1DD098397c59dD4', '0x8087a341D32D445d9aC8aCc9c14F5781E04A26d2', '0x974B7EE50982a57681daB70f5fe2041F415143a4']
    rewardTrackersForDepositBalances = ['0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342', '0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342', '0x908C4D94D34924765f1eDc22A1DD098397c59dD4', '0x4d268a7d4C16ceB5a606c173Bd974984343fea13', '0x4d268a7d4C16ceB5a606c173Bd974984343fea13', '0xd2D1162512F927a7e282Ef43a362659E4F2a728F']
    rewardTrackersForStakingInfo = ['0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342', '0x908C4D94D34924765f1eDc22A1DD098397c59dD4', '0x4d268a7d4C16ceB5a606c173Bd974984343fea13', '0x9e295B5B976a184B14aD8cd72413aD846C299660', '0xd2D1162512F927a7e282Ef43a362659E4F2a728F']
  }

  const [
    balances,
    stakingInfo,
    klpTotalSupply,
    klpAum,
    kingPrice
  ] = await Promise.all([
    rewardReader.getDepositBalances(account, depositTokens, rewardTrackersForDepositBalances),
    rewardReader.getStakingInfo(account, rewardTrackersForStakingInfo).then(info => {
      return rewardTrackersForStakingInfo.map((_, i) => {
        return info.slice(i * 5, (i + 1) * 5)
      })
    }),
    klpContract.totalSupply(),
    klpManager.getAumInUsdk(true),
    fetch('https://api.kingpin.finance/api/tokens/info?symbols=KING&chain=KAVA').then(async res => {
      const j = await res.json()
      return j['KING']['price']
    })
  ])

  const klpPrice = (klpAum / 1e18) / (klpTotalSupply / 1e18)
  const now = new Date()

  return {
    KLP: {
      stakedKLP: balances[5] / 1e18,
      pendingETH: stakingInfo[4][0] / 1e18,
      pendingEsKING: stakingInfo[3][0] / 1e18,
      klpPrice
    },
    KING: {
      stakedKING: balances[0] / 1e18,
      stakedEsKING: balances[1] / 1e18,
      pendingETH: stakingInfo[2][0] / 1e18,
      pendingEsKING: stakingInfo[0][0] / 1e18,
      kingPrice
    },
    timestamp: parseInt(now / 1000),
    datetime: now.toISOString()
  }
}


export const tokenSymbols = {
  // Kava
  '0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b': 'BTC',
  '0xe3f5a90f9cb311505cd691a46596599aa1a0ad7d': 'ETH',
  '0xc86c7c0efbd6a49b35e8714c5f59d99de09a225b': 'KAVA',
  '0xfa9343c3897324496a05fc75abed6bac29f8a40f': 'USDC',
  '0xb44a9b6905af7c801311e8f4e76932ee959c663c': 'USDT',
  '0x765277eebeca2e31912c9946eae1021199b39c61': 'DAI',

}

const knownSwapSources = {
  kava: {
    '0x0033d0c52228400240605caaee556320e00cd8bd': 'KING', // Router
    '0x1b1d7b2756e57d7ff45f3a42fafc4f9b41995646': 'Router', // Router
    '0x368ab183ce0b1acef4f8262fa04596dac2d61633': 'Orderbook', // Orderbook
    '0xe9f49708f9fc6e5053f12a6647052667db88d0ab': 'PositionManager', // PositionManager
    '0x78ea8a724b5dd68a8b8661e13c68a5f81ca015e5': 'OrderExecutor', // OrderExecutor
    '0x31EC635BCbA686D609220097ccDae91117fa0602': 'OrderExecutor', // OrderExecutor
    '0xa146e4760b8cf6dca4b1fad1f0f5ebe99ebcc704': 'Liquidator', // Liquidator
    '0x4d279f0b883611c89498897acf3753f62145166f': 'Liquidator', // Liquidator
    '0xf3f9a92b22d78810f4d6ac596feefe0258e33e29': 'FastPriceFeed', // FastPriceFeed
  }
}

const defaultFetcher = url => fetch(url).then(res => res.json())
export function useRequest(url, defaultValue, fetcher = defaultFetcher) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [data, setData] = useState(defaultValue)

  useEffect(async () => {
    try {
      setLoading(true)
      const data = await fetcher(url)
      setData(data)
    } catch (ex) {
      console.error(ex)
      setError(ex)
    }
    setLoading(false)
  }, [url])

  return [data, loading, error]
}

export function useCoingeckoPrices(symbol, { from = FIRST_DATE_TS } = {}) {
  // token ids https://api.coingecko.com/api/v3/coins
  const _symbol = {
    BTC: 'bitcoin',
    ETH: 'ethereum',
    KAVA: 'kava',
  }[symbol]

  const now = Date.now() / 1000
  const days = Math.ceil(now / 86400) - Math.ceil(from / 86400) - 1

  const url = `https://api.coingecko.com/api/v3/coins/${_symbol}/market_chart?vs_currency=usd&days=${days}&interval=daily`

  const [res, loading, error] = useRequest(url)

  const data = useMemo(() => {
    if (!res || res.length === 0) {
      return null
    }

    const ret = res.prices.map(item => {
      // -1 is for shifting to previous day
      // because CG uses first price of the day, but for KLP we store last price of the day
      const timestamp = item[0] - 1
      const groupTs = parseInt(timestamp / 1000 / 86400) * 86400
      return {
        timestamp: groupTs,
        value: item[1]
      }
    })
    return ret
  }, [res])

  return [data, loading, error]
}

function getImpermanentLoss(change) {
  return 2 * Math.sqrt(change) / (1 + change) - 1
}

function getChainSubgraph(chainName) {
  let subgraph;
  if (chainName === "kava") {
    subgraph = "kingpin-kava-stats"
  }
  // else if (chainName === "optimism") {
  //   subgraph = "kingpinfinance/op-stats"
  // }
  return subgraph
}

export function useGraph(querySource, { subgraph = null, subgraphUrl = null, chainName = "kava" } = {}) {
  const query = gql(querySource)

  if (!subgraphUrl) {
    if (!subgraph) {
      subgraph = getChainSubgraph(chainName)
    }
    let baseSubgraphUrl = `https://api.thegraph.com/subgraphs/name`
    if (chainName === "kava") {
      baseSubgraphUrl = `https://the-graph.kava.io/subgraphs/name`
    }
    subgraphUrl = `${baseSubgraphUrl}/${subgraph}`;
    // if (chainName === "optimism") {
    //   subgraphUrl = `https://subgraph.kingpin.finance/subgraphs/name/${subgraph}`;
    // }
  }

  const client = new ApolloClient({
    link: new HttpLink({ uri: subgraphUrl, fetch }),
    cache: new InMemoryCache()
  })
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    setLoading(true)
  }, [querySource, setLoading])

  useEffect(() => {
    client.query({ query }).then(res => {
      setData(res.data)
      setLoading(false)
    }).catch(ex => {
      console.warn('Subgraph request failed error: %s subgraphUrl: %s', ex.message, subgraphUrl)
      setError(ex)
      setLoading(false)
    })
  }, [querySource, setData, setError, setLoading])

  return [data, loading, error]
}

export function useLastBlock(chainName = "kava") {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  useEffect(() => {
    providers[chainName].getBlock()
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false))
  }, [])

  return [data, loading, error]
}

export function useLastSubgraphBlock(chainName = "kava") {
  const [data, loading, error] = useGraph(`{
    _meta {
      block {
        number
      }
    }
  }`, { chainName })
  const [block, setBlock] = useState(null)

  useEffect(() => {
    if (!data) {
      return
    }

    providers[chainName].getBlock(data._meta.block.number).then(block => {
      setBlock(block)
    })
  }, [data, setBlock])

  return [block, loading, error]
}

export function useTradersData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "kava" } = {}) {
  const [closedPositionsData, loading, error] = useGraph(`{
    tradingStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      timestamp
      profit
      loss
      profitCumulative
      lossCumulative
      longOpenInterest
      shortOpenInterest
    }
  }`, { chainName })
  const [feesData] = useFeesData({ from, to, chainName })
  const marginFeesByTs = useMemo(() => {
    if (!feesData) {
      return {}
    }

    let feesCumulative = 0
    return feesData.reduce((memo, { timestamp, margin: fees }) => {
      feesCumulative += fees
      memo[timestamp] = {
        fees,
        feesCumulative
      }
      return memo
    }, {})
  }, [feesData])

  let ret = null
  let currentPnlCumulative = 0;
  let currentProfitCumulative = 0;
  let currentLossCumulative = 0;
  const data = closedPositionsData ? sortBy(closedPositionsData.tradingStats, i => i.timestamp).map(dataItem => {
    const longOpenInterest = dataItem.longOpenInterest / 1e30
    const shortOpenInterest = dataItem.shortOpenInterest / 1e30
    const openInterest = longOpenInterest + shortOpenInterest

    // const fees = (marginFeesByTs[dataItem.timestamp]?.fees || 0)
    // const feesCumulative = (marginFeesByTs[dataItem.timestamp]?.feesCumulative || 0)

    const profit = dataItem.profit / 1e30
    const loss = dataItem.loss / 1e30
    const profitCumulative = dataItem.profitCumulative / 1e30
    const lossCumulative = dataItem.lossCumulative / 1e30
    const pnlCumulative = profitCumulative - lossCumulative
    const pnl = profit - loss
    currentProfitCumulative += profit
    currentLossCumulative -= loss
    currentPnlCumulative += pnl
    return {
      longOpenInterest,
      shortOpenInterest,
      openInterest,
      profit,
      loss: -loss,
      profitCumulative,
      lossCumulative: -lossCumulative,
      pnl,
      pnlCumulative,
      timestamp: dataItem.timestamp,
      currentPnlCumulative,
      currentLossCumulative,
      currentProfitCumulative
    }
  }) : null

  if (data && data.length) {
    const maxProfit = maxBy(data, item => item.profit).profit
    const maxLoss = minBy(data, item => item.loss).loss
    const maxProfitLoss = Math.max(maxProfit, -maxLoss)

    const maxPnl = maxBy(data, item => item.pnl).pnl
    const minPnl = minBy(data, item => item.pnl).pnl
    const maxCurrentCumulativePnl = maxBy(data, item => item.currentPnlCumulative).currentPnlCumulative
    const minCurrentCumulativePnl = minBy(data, item => item.currentPnlCumulative).currentPnlCumulative

    const currentProfitCumulative = data[data.length - 1].currentProfitCumulative
    const currentLossCumulative = data[data.length - 1].currentLossCumulative
    const stats = {
      maxProfit,
      maxLoss,
      maxProfitLoss,
      currentProfitCumulative,
      currentLossCumulative,
      maxCurrentCumulativeProfitLoss: Math.max(currentProfitCumulative, -currentLossCumulative),

      maxAbsPnl: Math.max(
        Math.abs(maxPnl),
        Math.abs(minPnl),
      ),
      maxAbsCumulativePnl: Math.max(
        Math.abs(maxCurrentCumulativePnl),
        Math.abs(minCurrentCumulativePnl)
      ),

    }

    ret = {
      data,
      stats
    }
  }

  return [ret, loading]
}

function getSwapSourcesFragment(skip = 0, from, to) {
  return `
    hourlyVolumeBySources(
      first: 1000
      skip: ${skip}
      orderBy: timestamp
      orderDirection: desc
      where: { timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      timestamp
      source
      swap
    }
  `
}
export function useSwapSources({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "kava" } = {}) {
  const query = `{
    a: ${getSwapSourcesFragment(0, from, to)}
    b: ${getSwapSourcesFragment(1000, from, to)}
    c: ${getSwapSourcesFragment(2000, from, to)}
    d: ${getSwapSourcesFragment(3000, from, to)}
    e: ${getSwapSourcesFragment(4000, from, to)}
  }`
  const [graphData, loading, error] = useGraph(query, { chainName })

  let data = useMemo(() => {
    if (!graphData) {
      return null
    }

    const { a, b, c, d, e } = graphData
    const all = [...a, ...b, ...c, ...d, ...e]

    const totalVolumeBySource = a.reduce((acc, item) => {
      const source = knownSwapSources[chainName][item.source] || item.source
      if (!acc[source]) {
        acc[source] = 0
      }
      acc[source] += item.swap / 1e30
      return acc
    }, {})
    const topVolumeSources = new Set(
      Object.entries(totalVolumeBySource).sort((a, b) => b[1] - a[1]).map(item => item[0]).slice(0, 30)
    )

    let ret = chain(all)
      .groupBy(item => parseInt(item.timestamp / 86400) * 86400)
      .map((values, timestamp) => {
        let all = 0
        const retItem = {
          timestamp: Number(timestamp),
          ...values.reduce((memo, item) => {
            let source = knownSwapSources[chainName][item.source] || item.source
            if (!topVolumeSources.has(source)) {
              source = 'Other'
            }
            if (item.swap != 0) {
              const volume = item.swap / 1e30
              memo[source] = memo[source] || 0
              memo[source] += volume
              all += volume
            }
            return memo
          }, {})
        }

        retItem.all = all

        return retItem
      })
      .sortBy(item => item.timestamp)
      .value()

    return ret
  }, [graphData])

  return [data, loading, error]
}

export function useTotalVolumeFromServer() {
  const [data, loading] = useRequest('https://king-server-mainnet.uw.r.appspot.com/total_volume')

  return useMemo(() => {
    if (!data) {
      return [data, loading]
    }

    const total = data.reduce((memo, item) => {
      return memo + parseInt(item.data.volume) / 1e30
    }, 0)
    return [total, loading]
  }, [data, loading])
}

function getServerHostname(chainName) {
  if (chainName == "optimism") {
    return 'king-avax-server.uc.r.appspot.com'
  }
  return 'king-server-mainnet.uw.r.appspot.com'
}

export function useVolumeDataRequest(url, defaultValue, from, to, fetcher = defaultFetcher) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [data, setData] = useState(defaultValue)

  useEffect(async () => {
    try {
      setLoading(true)
      const data = await fetcher(url)
      setData(data)
    } catch (ex) {
      console.error(ex)
      setError(ex)
    }
    setLoading(false)
  }, [url, from, to])

  return [data, loading, error]
}

export function useVolumeDataFromServer({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "kava" } = {}) {
  const PROPS = 'margin liquidation swap mint burn'.split(' ')
  const [data, loading] = useVolumeDataRequest(`https://${getServerHostname(chainName)}/daily_volume`, null, from, to, async url => {
    let after
    const ret = []
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const res = await (await fetch(url + (after ? `?after=${after}` : ''))).json()
      if (res.length === 0) return ret
      for (const item of res) {
        if (item.data.timestamp < from) {
          return ret
        }
        ret.push(item)
      }
      after = res[res.length - 1].id
    }
  })

  const ret = useMemo(() => {
    if (!data) {
      return null
    }

    const tmp = data.reduce((memo, item) => {
      const timestamp = item.data.timestamp
      if (timestamp < from || timestamp > to) {
        return memo
      }

      let type
      if (item.data.action === 'Swap') {
        type = 'swap'
      } else if (item.data.action === 'SellUSDK') {
        type = 'burn'
      } else if (item.data.action === 'BuyUSDK') {
        type = 'mint'
      } else if (item.data.action.includes('LiquidatePosition')) {
        type = 'liquidation'
      } else {
        type = 'margin'
      }
      const volume = Number(item.data.volume) / 1e30
      memo[timestamp] = memo[timestamp] || {}
      memo[timestamp][type] = memo[timestamp][type] || 0
      memo[timestamp][type] += volume
      return memo
    }, {})

    let cumulative = 0
    const cumulativeByTs = {}
    return Object.keys(tmp).sort().map(timestamp => {
      const item = tmp[timestamp]
      let all = 0

      let movingAverageAll
      const movingAverageTs = timestamp - MOVING_AVERAGE_PERIOD
      if (movingAverageTs in cumulativeByTs) {
        movingAverageAll = (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS
      }

      PROPS.forEach(prop => {
        if (item[prop]) all += item[prop]
      })
      cumulative += all
      cumulativeByTs[timestamp] = cumulative
      return {
        timestamp,
        all,
        cumulative,
        movingAverageAll,
        ...item
      }
    })
  }, [data, from, to])

  return [ret, loading]
}

export function useUsersData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "kava" } = {}) {
  const query = `{
    userStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      uniqueCount
      uniqueSwapCount
      uniqueMarginCount
      uniqueMintBurnCount
      uniqueCountCumulative
      uniqueSwapCountCumulative
      uniqueMarginCountCumulative
      uniqueMintBurnCountCumulative
      actionCount
      actionSwapCount
      actionMarginCount
      actionMintBurnCount
      timestamp
    }
  }`
  const [graphData, loading, error] = useGraph(query, { chainName })

  const prevUniqueCountCumulative = {}
  let cumulativeNewUserCount = 0;
  const data = graphData ? sortBy(graphData.userStats, 'timestamp').map(item => {
    const newCountData = ['', 'Swap', 'Margin', 'MintBurn'].reduce((memo, type) => {
      memo[`new${type}Count`] = prevUniqueCountCumulative[type]
        ? item[`unique${type}CountCumulative`] - prevUniqueCountCumulative[type]
        : item[`unique${type}Count`]
      prevUniqueCountCumulative[type] = item[`unique${type}CountCumulative`]
      return memo
    }, {})
    cumulativeNewUserCount += newCountData.newCount;
    const oldCount = item.uniqueCount - newCountData.newCount
    const oldPercent = (oldCount / item.uniqueCount * 100).toFixed(1)
    return {
      all: item.uniqueCount,
      uniqueSum: item.uniqueSwapCount + item.uniqueMarginCount + item.uniqueMintBurnCount,
      oldCount,
      oldPercent,
      cumulativeNewUserCount,
      ...newCountData,
      ...item
    }
  }) : null

  return [data, loading, error]
}

export function useFundingRateData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "kava" } = {}) {
  const query = `{
    fundingRates(
      first: 1000,
      orderBy: timestamp,
      orderDirection: desc,
      where: { period: "daily", id_gte: ${from}, id_lte: ${to} }
      subgraphError: allow
    ) {
      id,
      token,
      timestamp,
      startFundingRate,
      startTimestamp,
      endFundingRate,
      endTimestamp
    }
  }`
  const [graphData, loading, error] = useGraph(query, { chainName })


  const data = useMemo(() => {
    if (!graphData) {
      return null
    }

    const groups = graphData.fundingRates.reduce((memo, item) => {
      const symbol = tokenSymbols[item.token]
      if (symbol === 'MIM') {
        return memo
      }
      memo[item.timestamp] = memo[item.timestamp] || {
        timestamp: item.timestamp
      }
      const group = memo[item.timestamp]
      const timeDelta = parseInt((item.endTimestamp - item.startTimestamp) / 3600) * 3600

      let fundingRate = 0
      if (item.endFundingRate && item.startFundingRate) {
        const fundingDelta = item.endFundingRate - item.startFundingRate
        const divisor = timeDelta / 86400
        fundingRate = fundingDelta / divisor / 10000 * 365
      }
      group[symbol] = fundingRate
      return memo
    }, {})

    return fillNa(sortBy(Object.values(groups), 'timestamp'))
  }, [graphData])

  return [data, loading, error]
}

const MOVING_AVERAGE_DAYS = 7
const MOVING_AVERAGE_PERIOD = 86400 * MOVING_AVERAGE_DAYS

export function useVolumeData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "kava" } = {}) {
  const PROPS = 'margin liquidation swap mint burn'.split(' ')
  const timestampProp = "id"
  const query = `{
    volumeStats(
      first: 1000,
      orderBy: ${timestampProp},
      orderDirection: desc
      where: { period: daily, ${timestampProp}_gte: ${from}, ${timestampProp}_lte: ${to} }
      subgraphError: allow
    ) {
      ${timestampProp}
      ${PROPS.join('\n')}
    }
  }`
  const [graphData, loading, error] = useGraph(query, { chainName })

  const data = useMemo(() => {
    if (!graphData) {
      return null
    }

    let ret = sortBy(graphData.volumeStats, timestampProp).map(item => {
      const ret = { timestamp: item[timestampProp] };
      let all = 0;
      PROPS.forEach(prop => {
        ret[prop] = item[prop] / 1e30
        all += ret[prop]
      })
      ret.all = all
      return ret
    })

    let cumulative = 0
    const cumulativeByTs = {}
    return ret.map(item => {
      cumulative += item.all

      let movingAverageAll
      const movingAverageTs = item.timestamp - MOVING_AVERAGE_PERIOD
      if (movingAverageTs in cumulativeByTs) {
        movingAverageAll = (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS
      }

      return {
        movingAverageAll,
        cumulative,
        ...item
      }
    })
  }, [graphData])

  return [data, loading, error]
}
export function useVolume24HData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "kava" } = {}) {
  const PROPS = 'margin liquidation swap mint burn'.split(' ')
  const timestampProp = "id"
  const query = `{
    hourlyVolumes(
      first: 24,
      orderBy: ${timestampProp},
      orderDirection: desc
      subgraphError: allow
    ) {
      ${timestampProp}
      ${PROPS.join('\n')}
    }
  }`
  const [graphData, loading, error] = useGraph(query, { chainName })
  const data = useMemo(() => {
    if (!graphData) {
      return null
    }

    let ret = sortBy(graphData.hourlyVolumes, timestampProp).map(item => {
      const ret = { timestamp: item[timestampProp] };
      let all = 0;
      PROPS.forEach(prop => {
        ret[prop] = item[prop] / 1e30
        all += ret[prop]
      })
      ret.all = all
      return ret
    })

    let cumulative = 0
    ret.forEach(item => {
      cumulative += item.all
    })
    return cumulative
  }, [graphData])

  return [data, loading, error]
}
export function useFee24HData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "kava" } = {}) {
  const PROPS = 'marginAndLiquidation swap mint burn'.split(' ')
  const timestampProp = "id"
  const query = `{
    hourlyFees(
      first: 24,
      orderBy: ${timestampProp},
      orderDirection: desc
      subgraphError: allow
    ) {
      ${timestampProp}
      ${PROPS.join('\n')}
    }
  }`
  const [graphData, loading, error] = useGraph(query, { chainName })
  const data = useMemo(() => {
    if (!graphData) {
      return null
    }

    let ret = sortBy(graphData.hourlyFees, timestampProp).map(item => {
      const ret = { timestamp: item[timestampProp] };
      let all = 0;
      PROPS.forEach(prop => {
        ret[prop] = item[prop] / 1e30
        all += ret[prop]
      })
      ret.all = all
      return ret
    })

    let cumulative = 0
    ret.forEach(item => {
      cumulative += item.all
    })
    return cumulative
  }, [graphData])

  return [data, loading, error]
}

export function useFeesData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "kava" } = {}) {
  const PROPS = 'margin liquidation swap mint burn'.split(' ')
  const feesQuery = `{
    feeStats(
      first: 1000
      orderBy: id
      orderDirection: desc
      where: { period: daily, id_gte: ${from}, id_lte: ${to} }
      subgraphError: allow
    ) {
      id
      margin
      marginAndLiquidation
      swap
      mint
      burn
      ${chainName === "bnbchain" ? "timestamp" : ""}
    }
  }`
  let [feesData, loading, error] = useGraph(feesQuery, {
    chainName
  })

  const feesChartData = useMemo(() => {
    if (!feesData) {
      return null
    }

    let chartData = sortBy(feesData.feeStats, 'id').map(item => {
      const ret = { timestamp: item.timestamp || item.id };

      PROPS.forEach(prop => {
        if (item[prop]) {
          ret[prop] = item[prop] / 1e30
        }
      })

      ret.liquidation = item.marginAndLiquidation / 1e30 - item.margin / 1e30
      ret.all = PROPS.reduce((memo, prop) => memo + ret[prop], 0)
      return ret
    })

    let cumulative = 0
    const cumulativeByTs = {}
    return chain(chartData)
      .groupBy(item => item.timestamp)
      .map((values, timestamp) => {
        const all = sumBy(values, 'all')
        cumulative += all

        let movingAverageAll
        const movingAverageTs = timestamp - MOVING_AVERAGE_PERIOD
        if (movingAverageTs in cumulativeByTs) {
          movingAverageAll = (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS
        }

        const ret = {
          timestamp: Number(timestamp),
          all,
          cumulative,
          movingAverageAll
        }
        PROPS.forEach(prop => {
          ret[prop] = sumBy(values, prop)
        })
        cumulativeByTs[timestamp] = cumulative
        return ret
      })
      .value()
      .filter(item => item.timestamp >= from)
  }, [feesData])

  return [feesChartData, loading, error]
}

export function useAumPerformanceData({ from = FIRST_DATE_TS, to = NOW_TS, groupPeriod }) {
  const [feesData, feesLoading] = useFeesData({ from, to, groupPeriod })
  const [klpData, klpLoading] = useKlpData({ from, to, groupPeriod })
  const [volumeData, volumeLoading] = useVolumeData({ from, to, groupPeriod })

  const dailyCoef = 86400 / groupPeriod

  const data = useMemo(() => {
    if (!feesData || !klpData || !volumeData) {
      return null
    }

    const ret = feesData.map((feeItem, i) => {
      const klpItem = klpData[i]
      const volumeItem = volumeData[i]
      let apr = (feeItem?.all && klpItem?.aum) ? feeItem.all / klpItem.aum * 100 * 365 * dailyCoef : null
      if (apr > 10000) {
        apr = null
      }
      let usage = (volumeItem?.all && klpItem?.aum) ? volumeItem.all / klpItem.aum * 100 * dailyCoef : null
      if (usage > 10000) {
        usage = null
      }

      return {
        timestamp: feeItem.timestamp,
        apr,
        usage
      }
    })
    const averageApr = ret.reduce((memo, item) => item.apr + memo, 0) / ret.length
    ret.forEach(item => item.averageApr = averageApr)
    const averageUsage = ret.reduce((memo, item) => item.usage + memo, 0) / ret.length
    ret.forEach(item => item.averageUsage = averageUsage)
    return ret
  }, [feesData, klpData, volumeData])

  return [data, feesLoading || klpLoading || volumeLoading]
}

export function useKlpData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "kava" } = {}) {
  const timestampProp = 'id'
  const query = `{
    klpStats(
      first: 1000
      orderBy: ${timestampProp}
      orderDirection: desc
      where: {
        period: daily
        ${timestampProp}_gte: ${from}
        ${timestampProp}_lte: ${to}
      }
      subgraphError: allow
    ) {
      ${timestampProp}
      aumInUsdk
      klpSupply
      distributedUsd
      distributedEth
    }
  }`
  let [data, loading, error] = useGraph(query, { chainName })

  let cumulativeDistributedUsdPerKlp = 0
  let cumulativeDistributedEthPerKlp = 0
  const klpChartData = useMemo(() => {
    if (!data) {
      return null
    }

    let prevKlpSupply
    let prevAum

    if (!data.klpStats.length) return []
    let ret = sortBy(data.klpStats, item => item[timestampProp]).filter(item => item[timestampProp] % 86400 === 0).reduce((memo, item) => {
      const last = memo[memo.length - 1]

      const aum = Number(item.aumInUsdk) / 1e18
      const klpSupply = Number(item.klpSupply) / 1e18

      const distributedUsd = Number(item.distributedUsd) / 1e30
      const distributedUsdPerKlp = (distributedUsd / klpSupply) || 0
      cumulativeDistributedUsdPerKlp += distributedUsdPerKlp

      const distributedEth = Number(item.distributedEth) / 1e18
      const distributedEthPerKlp = (distributedEth / klpSupply) || 0
      cumulativeDistributedEthPerKlp += distributedEthPerKlp

      const klpPrice = aum / klpSupply
      const timestamp = parseInt(item[timestampProp])

      const newItem = {
        timestamp,
        aum,
        klpSupply,
        klpPrice,
        cumulativeDistributedEthPerKlp,
        cumulativeDistributedUsdPerKlp,
        distributedUsdPerKlp,
        distributedEthPerKlp
      }

      if (last && last.timestamp === timestamp) {
        memo[memo.length - 1] = newItem
      } else {
        memo.push(newItem)
      }

      return memo
    }, []).map(item => {
      let { klpSupply, aum } = item
      if (!klpSupply) {
        klpSupply = prevKlpSupply
      }
      if (!aum) {
        aum = prevAum
      }
      item.klpSupplyChange = prevKlpSupply ? (klpSupply - prevKlpSupply) / prevKlpSupply * 100 : 0
      if (item.klpSupplyChange > 1000) {
        item.klpSupplyChange = 0
      }
      item.aumChange = prevAum ? (aum - prevAum) / prevAum * 100 : 0
      if (item.aumChange > 1000) {
        item.aumChange = 0
      }
      prevKlpSupply = klpSupply
      prevAum = aum
      return item
    })

    ret = fillNa(ret)
    return ret
  }, [data])

  return [klpChartData, loading, error]
}

export function useKlpPerformanceData(klpData, feesData, { from = FIRST_DATE_TS, chainName = "kava" } = {}) {
  const [btcPrices] = useCoingeckoPrices('BTC', { from })
  const [ethPrices] = useCoingeckoPrices('ETH', { from })
  const [kavaPrices] = useCoingeckoPrices('KAVA', { from })

  const klpPerformanceChartData = useMemo(() => {
    if (!btcPrices || !ethPrices || !kavaPrices || !klpData || !feesData || !feesData.length) {
      return null
    }

    const klpDataById = klpData.reduce((memo, item) => {
      memo[item.timestamp] = item
      return memo
    }, {})

    const feesDataById = feesData.reduce((memo, item) => {
      memo[item.timestamp] = item
      return memo
    })

    let BTC_WEIGHT = 0
    let ETH_WEIGHT = 0
    let BNB_WEIGHT = 0

    if (chainName === "kava") {
      BTC_WEIGHT = 0.166
      ETH_WEIGHT = 0.166
      BNB_WEIGHT = 0.166
    } else {
      BTC_WEIGHT = 0.25
      ETH_WEIGHT = 0.25
    }

    const STABLE_WEIGHT = 1 - BTC_WEIGHT - ETH_WEIGHT - BNB_WEIGHT
    const KLP_START_PRICE = klpDataById[btcPrices[0].timestamp]?.klpPrice || 0

    const btcFirstPrice = btcPrices[0]?.value
    const ethFirstPrice = ethPrices[0]?.value
    let kavaFirstPrice
    if (chainName === "kava") {
      kavaFirstPrice = kavaPrices[0]?.value
    }
    // else if (chainName === "optimism") {
    //   kavaFirstPrice = opPrices[0]?.value
    // } 
    // else if (chainName === "arbitrum") {
    //   kavaFirstPrice = arbPrices[0]?.value
    // }

    let indexBtcCount = KLP_START_PRICE * BTC_WEIGHT / btcFirstPrice
    let indexEthCount = KLP_START_PRICE * ETH_WEIGHT / ethFirstPrice
    let indexAvaxCount = KLP_START_PRICE * BNB_WEIGHT / kavaFirstPrice
    let indexStableCount = KLP_START_PRICE * STABLE_WEIGHT

    const lpBtcCount = KLP_START_PRICE * 0.5 / btcFirstPrice
    const lpEthCount = KLP_START_PRICE * 0.5 / ethFirstPrice
    const lpAvaxCount = KLP_START_PRICE * 0.5 / kavaFirstPrice

    const ret = []
    let cumulativeFeesPerKlp = 0
    let lastKlpItem
    let lastFeesItem

    let prevEthPrice = 3400
    let prevAvaxPrice = 1000
    for (let i = 0; i < btcPrices.length; i++) {
      const btcPrice = btcPrices[i].value
      const ethPrice = ethPrices[i]?.value || prevEthPrice
      const avaxPrice = kavaPrices[i]?.value || prevAvaxPrice
      prevAvaxPrice = avaxPrice
      prevEthPrice = ethPrice

      const timestampGroup = parseInt(btcPrices[i].timestamp / 86400) * 86400
      const klpItem = klpDataById[timestampGroup] || lastKlpItem
      lastKlpItem = klpItem

      const klpPrice = klpItem?.klpPrice
      const klpSupply = klpItem?.klpSupply

      const feesItem = feesDataById[timestampGroup] || lastFeesItem
      lastFeesItem = feesItem

      const dailyFees = feesItem?.all

      const syntheticPrice = (
        indexBtcCount * btcPrice
        + indexEthCount * ethPrice
        + indexAvaxCount * avaxPrice
        + indexStableCount
      )

      // rebalance each day. can rebalance each X days
      if (i % 1 == 0) {
        indexBtcCount = syntheticPrice * BTC_WEIGHT / btcPrice
        indexEthCount = syntheticPrice * ETH_WEIGHT / ethPrice
        indexAvaxCount = syntheticPrice * BNB_WEIGHT / avaxPrice
        indexStableCount = syntheticPrice * STABLE_WEIGHT
      }

      const lpBtcPrice = (lpBtcCount * btcPrice + KLP_START_PRICE / 2) * (1 + getImpermanentLoss(btcPrice / btcFirstPrice))
      const lpEthPrice = (lpEthCount * ethPrice + KLP_START_PRICE / 2) * (1 + getImpermanentLoss(ethPrice / ethFirstPrice))
      const lpAvaxPrice = (lpAvaxCount * avaxPrice + KLP_START_PRICE / 2) * (1 + getImpermanentLoss(avaxPrice / kavaFirstPrice))

      if (dailyFees && klpSupply) {
        const INCREASED_KLP_REWARDS_TIMESTAMP = 1635714000
        const KLP_REWARDS_SHARE = timestampGroup >= INCREASED_KLP_REWARDS_TIMESTAMP ? 0.7 : 0.5
        const collectedFeesPerKlp = dailyFees / klpSupply * KLP_REWARDS_SHARE
        cumulativeFeesPerKlp += collectedFeesPerKlp
      }

      let klpPlusFees = klpPrice
      if (klpPrice && klpSupply && cumulativeFeesPerKlp) {
        klpPlusFees = klpPrice + cumulativeFeesPerKlp
      }

      let klpApr
      let klpPlusDistributedUsd
      let klpPlusDistributedEth
      if (klpItem) {
        if (klpItem.cumulativeDistributedUsdPerKlp) {
          klpPlusDistributedUsd = klpPrice + klpItem.cumulativeDistributedUsdPerKlp
          // klpApr = klpItem.distributedUsdPerKlp / klpPrice * 365 * 100 // incorrect?
        }
        if (klpItem.cumulativeDistributedEthPerKlp) {
          klpPlusDistributedEth = klpPrice + klpItem.cumulativeDistributedEthPerKlp * ethPrice
        }
      }

      ret.push({
        timestamp: btcPrices[i].timestamp,
        syntheticPrice,
        lpBtcPrice,
        lpEthPrice,
        lpAvaxPrice,
        klpPrice,
        btcPrice,
        ethPrice,
        klpPlusFees,
        klpPlusDistributedUsd,
        klpPlusDistributedEth,

        indexBtcCount,
        indexEthCount,
        indexAvaxCount,
        indexStableCount,

        BTC_WEIGHT,
        ETH_WEIGHT,
        BNB_WEIGHT,
        STABLE_WEIGHT,

        performanceLpEth: (klpPrice / lpEthPrice * 100).toFixed(2),
        performanceLpEthCollectedFees: (klpPlusFees / lpEthPrice * 100).toFixed(2),
        performanceLpEthDistributedUsd: (klpPlusDistributedUsd / lpEthPrice * 100).toFixed(2),
        performanceLpEthDistributedEth: (klpPlusDistributedEth / lpEthPrice * 100).toFixed(2),

        performanceLpBtcCollectedFees: (klpPlusFees / lpBtcPrice * 100).toFixed(2),

        performanceLpAvaxCollectedFees: (klpPlusFees / lpAvaxPrice * 100).toFixed(2),

        performanceSynthetic: (klpPrice / syntheticPrice * 100).toFixed(2),
        performanceSyntheticCollectedFees: (klpPlusFees / syntheticPrice * 100).toFixed(2),
        performanceSyntheticDistributedUsd: (klpPlusDistributedUsd / syntheticPrice * 100).toFixed(2),
        performanceSyntheticDistributedEth: (klpPlusDistributedEth / syntheticPrice * 100).toFixed(2),

        klpApr
      })
    }

    return ret
  }, [btcPrices, ethPrices, klpData, feesData])

  return [klpPerformanceChartData]
}

export function useTokenStats({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  period = 'daily',
  chainName = "kava"
} = {}) {

  const getTokenStatsFragment = ({ skip = 0 } = {}) => `
    tokenStats(
      first: 1000,
      skip: ${skip},
      orderBy: timestamp,
      orderDirection: desc,
      where: { period: ${period}, timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      poolAmountUsd
      timestamp
      token
    }
  `

  // Request more than 1000 records to retrieve maximum stats for period
  const query = `{
    a: ${getTokenStatsFragment()}
    b: ${getTokenStatsFragment({ skip: 1000 })},
    c: ${getTokenStatsFragment({ skip: 2000 })},
    d: ${getTokenStatsFragment({ skip: 3000 })},
    e: ${getTokenStatsFragment({ skip: 4000 })},
    f: ${getTokenStatsFragment({ skip: 5000 })},
  }`

  const [graphData, loading, error] = useGraph(query, { chainName })

  const data = useMemo(() => {
    if (loading || !graphData) {
      return null;
    }

    const fullData = Object.values(graphData).reduce((memo, records) => {
      memo.push(...records);
      return memo;
    }, []);

    const retrievedTokens = new Set();

    const timestampGroups = fullData.reduce((memo, item) => {
      const { timestamp, token, ...stats } = item;

      const symbol = tokenSymbols[token] || token;

      retrievedTokens.add(symbol);

      memo[timestamp] = memo[timestamp || 0] || {};

      memo[timestamp][symbol] = {
        poolAmountUsd: parseInt(stats.poolAmountUsd) / 1e30,
      };

      return memo;
    }, {});

    const poolAmountUsdRecords = [];

    Object.entries(timestampGroups).forEach(([timestamp, dataItem]) => {
      const poolAmountUsdRecord = Object.entries(dataItem).reduce((memo, [token, stats]) => {
        memo.all += stats.poolAmountUsd;
        memo[token] = stats.poolAmountUsd;
        memo.timestamp = timestamp;

        return memo;
      }, { all: 0 });

      poolAmountUsdRecords.push(poolAmountUsdRecord);
    })

    return {
      poolAmountUsd: poolAmountUsdRecords,
      tokenSymbols: Array.from(retrievedTokens),
    };
  }, [graphData, loading])

  return [data, loading, error]
}

export function useReferralsData({ from = FIRST_DATE_TS, to = NOW_TS, chainName = "kava" } = {}) {
  const query = `{
    globalStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      volume
      volumeCumulative
      totalRebateUsd
      totalRebateUsdCumulative
      discountUsd
      discountUsdCumulative
      referrersCount
      referrersCountCumulative
      referralCodesCount
      referralCodesCountCumulative
      referralsCount
      referralsCountCumulative
      timestamp
    }
  }`
  let subgraph;
  if (chainName === "kava") {
    subgraph = "kingpinfinance/kava-referrals"
  } else if (chainName === "optimism") {
    subgraph = "kingpinfinance/op-referrals"
  } else if (chainName === "optimism") {
    subgraph = "kingpinfinance/arbitrum-referrals"
  }
  const [graphData, loading, error] = useGraph(query, { subgraph })

  const data = graphData ? sortBy(graphData.globalStats, 'timestamp').map(item => {
    const totalRebateUsd = item.totalRebateUsd / 1e30
    const discountUsd = item.discountUsd / 1e30
    return {
      ...item,
      volume: item.volume / 1e30,
      volumeCumulative: item.volumeCumulative / 1e30,
      totalRebateUsd,
      totalRebateUsdCumulative: item.totalRebateUsdCumulative / 1e30,
      discountUsd,
      referrerRebateUsd: totalRebateUsd - discountUsd,
      discountUsdCumulative: item.discountUsdCumulative / 1e30,
      referralCodesCount: parseInt(item.referralCodesCount),
      referralCodesCountCumulative: parseInt(item.referralCodesCountCumulative),
      referrersCount: parseInt(item.referrersCount),
      referrersCountCumulative: parseInt(item.referrersCountCumulative),
      referralsCount: parseInt(item.referralsCount),
      referralsCountCumulative: parseInt(item.referralsCountCumulative),
    }
  }) : null

  return [data, loading, error]
}
